<template>
<div class="page_content">
<!--  <div class="banner_wrapper">-->

<!--  </div>-->
  <div class="bottom_content content">
    <div class="content_left">
      <div class="content_inner">
        <div class="content_title">
          {{article.title}}
        </div>
        <div class="content_sub_title">{{article.subTitle}}</div>
        <div class="content_title">
          <span class="normal_gray" style="margin-top:15px;">发表时间：{{article.createDate}}</span>
        </div>
        <div class="content_detail" v-html="article.description"></div>
      </div>

    </div>
    <div class="content_right">
      <right-ad></right-ad>
    </div>
  </div>
</div>
</template>

<script>
import RightAd from "@/components/rightAd";
import {getArticle} from "@/api/user/user";
export default {
  name: "content",
  components: {RightAd},
  data(){
    return {
      article:{}
    }
  },
  mounted(){
    if(this.$route.params.id !== undefined){
      getArticle({
        uid:this.$route.params.id
      }).then(res => {
        if(res.code === 0){
          this.article = res.data;
          document.title = res.data.title;
        }
      });
    }
  }
}
</script>

<style lang="less">
.page_content{
  .bottom_content{
    display:flex;
    justify-content: space-between;
    margin-top:20px;
  }
  .content_left{
    background-color:#fff;
    width:862px;
  }
  .content_inner{
    margin:30px;
  }
  .banner_wrapper{
    position: relative;
    height: 310px;
    background-color: #666ee8;
  }
  .content_title{
    font-size: 32px;
    color: #1a1a1a;
    font-weight: bold;
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    span{
      font-weight:normal;
    }
  }
  .content_sub_title{
    font-size: 19px;
    margin-top:15px;
    color: #1a1a1a;
  }
  .content_detail{
    margin-top:50px;
  }
  .content_right{
    width:312px;
  }
}


</style>
